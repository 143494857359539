$black: #181f28;
$light-black: #2d3a4b;
$white: #ffffff;
$gray: #f5f5f5;

.main {
  margin: 2.25rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: hidden;

  .overlay-fs {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f9f8f9;
    z-index: 9;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .m-header {
    position: fixed;
    // top: 4.04em;
    left: 0;
    right: 0;
    padding: 0.25rem 0rem;
    margin: 15px;
    z-index: 100;
    // width: 100%;
    height: 3.25rem;
    color: $black;
    background-color: #ffffe9;

    .m-header-container {
      .h-left,
      .h-right {
        height: 100%;
      }

      .h-left {
        justify-content: center;
        max-width: 100%;
        flex-direction: column;
        margin: 0rem -0.2rem 0rem -1rem;
        cursor: pointer;

        .uk-inline {
          margin: -1rem 0rem;
        }

        .h-logo {
          width: 167px;
          max-width: 167px;
        }
      }

      .h-center {
        flex-direction: column;
        padding: 0.2rem 0;
        font-size: 0.95rem;

        .h-center-up,
        .h-center-down {
          display: flex;
          align-items: center;
          // color: $white;
          height: 50%;
          line-height: 1.2rem;
          div {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        // .h-center-up {
        //   font-weight: bold;
        // }
        .h-center-down {
          font-size: 11px;
        }
      }
    }

    .h-right {
      flex-wrap: wrap;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      width: 16.5rem;

      .h-right-circles {
        display: flex;
        align-items: center;
        width: 34px;
        height: 34px;
        margin-top: 0.3rem;

        .r-circle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 34px;
          height: 34px;
          color: white;
          cursor: pointer;
        }
      }

      .h-right-logo {
        padding-left: 0.5rem;
        margin-left: 0.2rem;
        border-left: 1px solid #fff;
      }

      // .folder-dropdown {
      //   margin: -1rem 0rem;
      //   cursor: pointer;

      //   .uk-dropdown {
      //     min-width: 167px;
      //     padding: 0;

      //     ul > li {
      //       padding: 5px 15px;
      //       &:hover {
      //         background-color: #e8ebf6;
      //       }

      //       label {
      //         margin: 0;
      //         div {
      //           width: 135px;
      //           cursor: pointer;
      //         }
      //       }
      //     }
      //   }
      // }

      .other-dropdown {
        .uk-dropdown {
          padding: 0;

          .uk-nav-header {
            padding: 5px 10px;
            text-transform: uppercase;
            font-size: 0.5rem;
            font-weight: bold;
          }

          .uk-dropdown-nav {
            font-size: 0.75rem;

            .selected {
              background: #e8ebf7;
            }
          }

          .uk-nav > li > a,
          .uk-nav > li > div {
            cursor: pointer;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .m-main {
    margin-bottom: 1rem;
    margin-top: .5rem;
    flex-wrap: nowrap;
    padding: 0;

    .right-panel-container {
      position: fixed;
      right: -240px;
      padding-left: 0;
      padding-right: 0;
      z-index: 10;
      flex: 0 0 240px;
      max-width: 240px;
      background-color: #fff;
      padding: 5px;
      box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);

      .rp-footer {
        position: fixed;
        width: 220px;
        bottom: 0;
        right: 0;
        height: 2.22rem;
        background-color: #f9f8f9;

        .f-right-down {
          position: fixed;
          bottom: 0.5rem;
          right: 1rem;
          font-size: 0.75rem;

          .f-logo {
            max-width: 100%;
            max-height: 14px;
            margin-bottom: 0.2rem;
            margin-left: 0.4rem;
          }
        }
      }
    }

    .activeRPanel {
      transition: all 0.5s ease-out;

      .table-container {
        z-index: 2;

        &:hover {
          .cog-menu {
            display: block;
          }
        }
      }

      .table-container,
      .charts-container {
        // margin-top: 0.2rem;
        flex-direction: column;
        padding-right: 0px;
        padding-left: 0px;

        .chart-tabs,
        .table-tabs {
          width: 100%;
          margin-bottom: 0.1rem;

          .chart-tabs-buttons,
          .table-tabs-buttons {
            display: inline-block;
            vertical-align: bottom;
            font-size: 12px;
            width: 5rem;
            text-align: center;
            margin: 1px 2px 1px 0;
            padding: 1px 0px;
            border-radius: 3px;
            background-color: gainsboro;
            cursor: pointer;

            &.selected {
              background-color: #566f8f;
              color: #ffffff;
            }

            .button {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                width: 1rem;
              }
              svg {
                width: 1rem;
                height: 1rem;
                margin-right: 3px;
              }
            }
          }

          .large-tab {
            width: 7rem;
          }
        }

        .table-tabs {
          display: flex;
          justify-content: space-between;
        }

        .charts-content {
          width: 100%;
          margin-left: 0;

          .ch-c-chart {
            padding: 0;
            text-align: center;
            position: relative;
            height: 282px;

            .chart-download-background {
              display: block;
              position: absolute;
              width: 60px;
              margin-top: -30px;
              margin-right: -30px;
              top: 0;
              right: 0;
              height: 60px;
              border-style: solid;
              border-color: transparent transparent transparent #c7cce5a6;
              border-width: 30px;
              -webkit-transform: rotate(180deg);
              transform: rotate(-45deg);
              // cursor: pointer;

              // &:hover {
              //   border-color: transparent transparent transparent #7080ad;
              // }
            }

            .chart-icons-container {
              position: absolute;
              display: none;
              top: 0;
              right: 0;
              // display: flex;
              margin-top: 2px;
              margin-right: 2px;

              .h-l-under-head-line {
                .h-l-plus-minus {
                  margin-top: 7px;
                  display: inline-flex;
                  cursor: pointer;

                  .plus-minus-styles {
                    display: flex;
                    align-items: center;
                  }
                }
              }

              .chart-download-icon {
                width: 24px;
                height: 24px;
                color: white;
                background-color: transparent;
                cursor: pointer;

                .uk-dropdown {
                  min-width: 80px;
                  padding: 3px 8px;

                  .uk-nav > li > a {
                    padding: 0;
                  }
                }
              }

              .fs_styles {
                width: 18px;
                height: 18px;
                margin-top: 0px;
                margin-right: -4px;
                margin-left: 5px;
              }

              &.visible {
                display: flex;
              }
            }

            @media only screen and (max-width : 1024px) {
              .chart-icons-container {
                display: flex;
              }
            }

            .head-line-chart {
              position: relative;
              width: 100%;
              display: block;
              text-align: center;

              h5 {
                text-align: left;
                font-size: 12px;
                margin: 5px 0 0 10px;
              }

              .h-l-text {
                font-style: italic;
                font-weight: 500;
              }

              .h-l-radio-buttons {
                & form {
                  display: inline-flex;

                  .radio-buttons {
                    margin: 0 2px;
                  }
                }
              }
            }
          }

          .uk-first-column {
            padding-left: 0;
          }

          .show-insights-container {
            width: 100%;

            .show-insights-block {
              width: 100%;
              margin: 0.3rem 0;
              display: flex;
              justify-content: center;

              .sh-ins-button {
                height: 1.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                width: 6rem;
                text-align: center;
                margin: 1px 2px 1px 0;
                padding: 1px 0px;
                border-radius: 3px;
                background-color: #4BB543;
                color: #ffffff;
                cursor: pointer;

                .insights-spinner {
                  display: flex;
                  margin-left: .5rem;
                }
              }
            }
          }
        }
      }

      .w-icon {
        width: .85em;
        height: .85em;
      }
      .w-icon-small {
        width: .75em;
        height: .85em;
      }
      .w-icon-large {
        width: 1em;
        height: 1em;
      }
    }

    .full-screen-styles {
      position: fixed !important;
      top: 68px;
      left: 10px;
      right: 33px;
      bottom: 10px;
      height: auto;
      z-index: 10;
      box-shadow: none;
      height: unset !important;
    }

    &.with-right-panel {
      padding: 0px 250px 0px 0px;

      .right-panel-container {
        right: 16px;
        // height: 100%;
      }

      .full-screen-styles {
        right: 262px;
      }
    }

    &.fs-right-panel {
      margin-top: 0;
    }

    @media only screen and (max-width: 480px) {
      &.with-right-panel {
        padding: 0;
      }
    }

    // -webkit-transition: padding 1s, right 1s; /* Safari prior 6.1 */
    // transition: padding 1s, right 1s;

    .chart-control-custom {
      width: 140px;
      position: absolute;
      top: 0;
      right: 10px;
      padding: .2rem;

      .list {
        font-size: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-y: scroll;
        overflow-x: hidden;
        height: 225px;
        border: 1px solid lightgray;
        text-align: left;
        padding: 0;
        margin: 10px 0;

        .list-item {
          padding: 0px 5px;
          margin-bottom: 1px;
          cursor: pointer;

          &.selected {
            // background-color: #181f27;
            background-color: #566f8f;
            color: #ffffff;
          }
          &:hover {
            background-color: #dce6f0;
          }
        }

      }
    }

    .chart-controls {
      width: 100%;
      text-align: right;
      font-size: 12px;
      display: inline;
      vertical-align: bottom;

      .radio-control {
        display: inline-block;
        margin-top: 3px;
        margin-left: 0;
        .uk-radio {
          transform: scale(.8);
          // width: 12px;
          // height: 12px;
        }
      }

      .checkbox-control {
        display: inline-block;
        .uk-checkbox {
          transform: scale(.8);
        }
      }

      .vertical-divider {
        margin: 0rem 1rem;
      }

      .uk-form-label, .uk-form-controls {
        display: inline-block;
        vertical-align: top;
        margin-left: .4rem;
        // margin-bottom: 0;
      }
      .uk-select:not([multiple]):not([size]) {
        height: 23px;
        font-size: 12px;
        width: 100px;
      }
    }

    .help-button {
      border: 1px solid red;
      border-radius: 50%;
      width: 16px;
      line-height: 16px;
      height: 16px;
      text-align: center;
      font-weight: bold;
      color: red;
      cursor: pointer;
    }

    .help-message {

    }

    .MSE_Comp {
      padding-right: 160px;
      position: relative;
    }



    .yseop-logo {
      width: 6rem;
      position: absolute;
      bottom: 2px;
      right: 6px;
    }
  }

  .m-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    height: 4rem;
    background-color: rgba(255, 255, 255, 0.8);

    .f-left {
      .f-left-container {
        flex-direction: column;

        .left-style {
          display: flex;
          align-items: flex-start;
          margin-bottom: 1px;

          .l-words {
            width: 100%;
            margin-left: 0.2rem;
            margin-top: 0.4rem;
            color: grey;
            font-size: 8px;
            font-weight: 500;
          }
        }
      }
    }
    .l-circle,
    .r-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      cursor: pointer;
      width: 32px;
      height: 32px;
      margin-bottom: -0.3rem;
    }

    .f-right {
      flex-direction: column;
      align-items: center;
      padding: 0;

      .f-right-up {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 12px;
      }

      .f-right-down {
        position: absolute;
        bottom: 0.5rem;
        right: 1rem;
        font-size: 0.75rem;

        .f-logo {
          max-width: 100%;
          max-height: 14px;
          margin-bottom: 0.2rem;
          margin-left: 0.4rem;
        }
      }
    }
  }

  .m-tooltip {
    height: calc(100% - 23px);
    width: calc(100% - 169px);
    background-color: white;
    position: absolute;
    z-index: 3;
    right: 0;
    top: 23px;
    padding: 2px;
    border: 1px solid rgba(211, 211, 211, 0.534);

    .tooltip-container {
      padding: 0 15px;

      .tooltip-header {
        height: 1.5rem;
        border-bottom: 1px solid rgba(128, 128, 128, 0.267);
        display: flex;

        .h-word {
          font-size: 13px;
          font-weight: 700;
        }
      }

      .tooltip-charts {
        display: flex;
        height: 15rem;
        overflow-x: auto;
        padding: 0px 10px;

        .tooltip-charts-item {
          min-width: 170px;
          margin-bottom: 10px;
        }

        .uk-card-body {
          padding: 5px 10px;

          .t-card-header,
          .t-card-value {
            display: flex;
            align-items: center;
          }

          .t-card-header {
            justify-content: space-between;

            .c-h-title {
              font-size: 20px;
              font-weight: 700;
            }
          }

          .t-card-value {
            justify-content: center;
            height: 2rem;

            .c-value {
              font-size: 1.8rem;
              font-weight: 600;
              color: #137fd0;
            }
          }

          .c-h-icon {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }

  .react-contextmenu {
    z-index: 100;
  }

  // Market Access
  .market_access-footnote {
    font-size: .81rem;
    font-weight: bold;
    text-align: left;
    margin: 1px 10px;
  }

  .multi-select {
    width: 175px;
    text-align: left;
    display: inline-block;
    margin-left: 5px;
    --rmsc-primary: #4285f4;
    --rmsc-hover: #f1f3f5;
    --rmsc-selected: #e2e6ea;
    --rmsc-border: #ccc;
    --rmsc-gray: #aaa;
    --rmsc-background: #fff;
    --rmsc-spacing: 10px;
    --rmsc-border-radius: 0px;
    --rmsc-height: 22px;

    label {
      margin-bottom: 0;
    }
  }
}
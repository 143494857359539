.multi-chart {
  .bar-line-svg, .stacked-svg, .multiline-svg {
    .x-axis {
      .domain {
        stroke: none;
      }
    }
  }
  .line {
    fill: none;
  }
  .dot {
    fill: #fff;
    cursor: pointer;
  }
}

.multiline-brush, .barline-brush {
  .line {
    fill: none;
    stroke: steelblue;
    stroke-width: 2px;
  }
  
  .tick line {
    opacity: 0.2;
  }
  
  .zoom {
    cursor: move;
    fill: none;
    pointer-events: all;
  }
  
  .brush {
    pointer-events: all;
  }
  
  .right,
  .left {
    stroke: #000;
    stroke-width: 0.4;
    fill: #fff;
    fill-opacity: 0.7;
  }
  
  .selection {
    fill-opacity: 0;
    fill: none;
    stroke: none;
  }
  
  .tooltip {
    font-size: 10px;
    padding: 6px;
    border-radius: 3px;
    background-color: hsla(0, 0%, 47%, 0.6);
    color: #fff;
    pointer-events: none;
  
    .square {
      width: 10px;
      height: 10px;
      margin-right: 4px;
      margin-top: -2px;
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #fff;
    }
  }  
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
  // font-size: 10px;
  padding: 10px;
  border-radius: 3px;
  background-color: rgba(18, 18, 18, 0.849);
  color: #fff;
  pointer-events: none;

  ul {
    margin: 0px;
    padding-left: 0;

    li {
      list-style-type: none;
    }
  }
  
  .square {
    width: 10px;
    height: 10px;
    padding: 5px;
    margin-right: 4px;
    margin-top: -4px;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #fff;
  }
}